import { ref } from '@vue/reactivity';

export function useLoading() {
  const active = ref(false);

  const start = () => {
    active.value = true;
  };

  const finish = () => {
    active.value = false;
  };

  return {
    active,
    start,
    finish,
  };
}
